<template>
    <div class="login_main">
        <div class="login_panel">
            <h2 style="text-align: center">
                <img src="../../assets/images/logo/ptn/logo01.png" style="height: 60px"> 에이전트
            </h2>
            <div class="form">
                <label>
                    <input class="ipt" type="text" v-model="agent.account" placeholder="아이디"></input>
                </label>
                <label>
                    <input class="ipt" type="password" v-model="agent.passwd" placeholder="비밀번호"></input>
                </label>
                <el-button
                        v-loading.fullscreen.lock="fullscreenLoading"
                        class="loginButton" @click="doLogin"><i class="fa fa-sign-in"></i> 로그인</el-button>
            </div>

        </div>
    </div>
</template>

<script>
    import {doLogin} from "../../network/agent/commonRequest";

    export default {
        name: "AgentLogin",
        data() {
            return {
                agent: {
                    account: '',
                    passwd: '',
                },
                fullscreenLoading:false,
            }
        }
        ,
        methods: {
            doLogin(){
                this.fullscreenLoading =true;
                doLogin(this.agent).then(res=>{
                    this.fullscreenLoading=false;
                    if(res.data.success){
                        this.$store.state.agent.agentInfo = res.data.data
                        console.log( this.$store.state.agent.agentInfo)
                        this.$router.push({path:'agent'})
                    }else {
                        this.$message.error(res.data.msg);
                    }
                })

            }
        }
    }
</script>

<style scoped>
    @import url("../../assets/css/adminsitrator.css");

    .login_main {
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        font: 100% / 1.5 Raleway, sans-serif;
        color: hsl(230, 100%, 95%);
        background: #b3d8ff !important;
    }

    .login_panel {
        width: 570px;
        height: 200px;
    }
    .form{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .form label{
        width: 100%;
        text-align: center;
    }
    .form .ipt{
        width: 90%;
        background-color: #8dc4fe;
        text-align: center;
        margin: 5px;
        line-height: 36px!important;
        height: 38px!important;
        outline: transparent;
        border: 1px solid #dcebfb;
        color: #000000;
        border-radius: 30px;
    }
    .loginButton{
        width: 90%;
        border-radius: 30px;
        border: 1px solid #dcebfb;
        line-height: 18px;
        background-color: #8dc4fe;
        color: #000000;
        font-weight: bold;
        cursor: pointer;
        margin-top: 3px;
    }
    button:hover{
        background-color: #d7ddfc;
        color: #0c0e0e;
    }

    @keyframes lights {
        0% {
            color: hsl(230, 40%, 80%);
            text-shadow: 0 0 1em hsla(320, 100%, 50%, 0.2),
            0 0 0.125em hsla(320, 100%, 60%, 0.3),
            -1em -0.125em 0.5em hsla(40, 100%, 60%, 0),
            1em 0.125em 0.5em hsla(200, 100%, 60%, 0);
        }

        30% {
            color: hsl(230, 80%, 90%);
            text-shadow: 0 0 1em hsla(320, 100%, 50%, 0.5),
            0 0 0.125em hsla(320, 100%, 60%, 0.5),
            -0.5em -0.125em 0.25em hsla(40, 100%, 60%, 0.2),
            0.5em 0.125em 0.25em hsla(200, 100%, 60%, 0.4);
        }

        40% {
            color: hsl(230, 100%, 95%);
            text-shadow: 0 0 1em hsla(320, 100%, 50%, 0.5),
            0 0 0.125em hsla(320, 100%, 90%, 0.5),
            -0.25em -0.125em 0.125em hsla(40, 100%, 60%, 0.2),
            0.25em 0.125em 0.125em hsla(200, 100%, 60%, 0.4);
        }

        70% {
            color: hsl(230, 80%, 90%);
            text-shadow: 0 0 1em hsla(320, 100%, 50%, 0.5),
            0 0 0.125em hsla(320, 100%, 60%, 0.5),
            0.5em -0.125em 0.25em hsla(40, 100%, 60%, 0.2),
            -0.5em 0.125em 0.25em hsla(200, 100%, 60%, 0.4);
        }

        100% {
            color: hsl(230, 40%, 80%);
            text-shadow: 0 0 1em hsla(320, 100%, 50%, 0.2),
            0 0 0.125em hsla(320, 100%, 60%, 0.3),
            1em -0.125em 0.5em hsla(40, 100%, 60%, 0),
            -1em 0.125em 0.5em hsla(200, 100%, 60%, 0);
        }

    }

    h2 {
        margin: auto;
        font-size: 3.5rem;
        font-weight: 300;
        animation: lights 5s 750ms linear infinite;
    }

</style>